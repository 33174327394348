html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

@media print {
  html,
  body {
    color: #707172 !important; }
  *,
  *:before,
  *:after {
    color: inherit !important; }
  a:after {
    content: none !important; }
  table,
  table tr,
  table th,
  table td {
    font-size: 1vw !important;
    word-wrap: break-word; }
  .page-wrapper {
    padding-top: 0px !important; }
  .form-language,
  .header-top-block1,
  .nav-toggle,
  .nav-sections,
  .mageshops-bannerslider,
  .collapsible-navigation,
  .product-social-links,
  .block.related,
  .block.upsell,
  .fotorama__nav.fotorama__nav--dots,
  .modal-popup .modal-header,
  .modal-popup .modal-footer,
  footer,
  .page-footer,
  .footer-bottom,
  .print-product {
    display: none !important; }
  #page-static-header {
    position: static !important; }
  .header.content {
    padding-top: 0px; }
  .logo {
    float: right; }
    .logo:after {
      clear: both; }
  #maincontent {
    clear: both; }
  .page-main,
  .column.main {
    min-height: initial !important; }
  .page-main {
    clear: both; }
  .fotorama,
  .fotorama__stage,
  .fotorama__stage__shaft,
  .fotorama__stage {
    width: 300px !important;
    height: 300px !important; }
  .product-info-main .page-title-wrapper .page-title {
    margin-top: 0px; }
  .fotorama__nav.fotorama__nav--thumbs {
    display: block; }
  .product.data.items .item.content {
    border: none;
    padding: 0px; }
  .product.data.items > .item.title {
    float: none !important;
    margin: 0px !important;
    padding: 0px !important;
    border: none !important; }
  .product.data.items .item.title > .switch,
  .product.data.items .item.title.active > .switch {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 2px;
    border-bottom-color: #707172;
    margin: 0px !important;
    padding: 0px !important;
    color: #707172 !important;
    font-weight: normal;
    font-size: 1.6em !important; }
  .product.data.items > .item.content {
    float: none;
    margin-left: 0px !important;
    margin: 0px;
    padding: 0px;
    background: none;
    display: block !important; }
  .ps-container {
    overflow: initial !important; }
  .additional-attributes-wrapper {
    max-height: none; }
  .product.info.detailed .additional-attributes {
    width: 100%;
    max-width: 100%; }
  .modals-wrapper {
    display: block !important; }
  .modal-popup {
    display: block !important;
    position: static !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important; }
    .modal-popup .modal-inner-wrap {
      margin: 0px !important;
      width: auto !important;
      position: static !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
      display: block !important;
      transform: none !important; }
    .modal-popup .modal-content {
      background: none;
      padding: 0px; }
  .contact-wrapper .formbuilder-form {
    display: none; }
  .contact-wrapper .contact-information {
    float: none;
    width: 100%; } }

// Mixins
@import "mixins/media-queries";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
//@import 'vendor/magento-ui/lib'; // Magento UI

//boostrap light
//@import 'bootstrap-light';

// Theme variables
//@import 'variables';

@media print {
  // Overall styles
  html,
  body {
    color: #707172 !important;
  }

  *,
  *:before,
  *:after {
    color: inherit !important;
  }

  a:after {
    content: none !important;
  }

  table,
  table tr,
  table th,
  table td {
    font-size: 1vw !important;
    word-wrap: break-word;
  }

  .page-wrapper {
    padding-top: 0px !important;
  }

  // Hide these elements
  .form-language,
  .header-top-block1,
  .nav-toggle,
  .nav-sections,
  .mageshops-bannerslider,
  .collapsible-navigation,
  .product-social-links,
  .block.related,
  .block.upsell,
  .fotorama__nav.fotorama__nav--dots,
  .modal-popup .modal-header,
  .modal-popup .modal-footer,
  footer,
  .page-footer,
  .footer-bottom,
  .print-product {
    display: none !important;
  }

  // Header
  #page-static-header {
    position: static !important;
  //   clear: both;

  //   .page-header {
  //     padding-bottom: 0px;
  //     clear: both;
  //   }
  }

  .header.content {
    padding-top: 0px;
  }

  .logo {
    float: right;

    &:after {
      clear: both;
    }
  }

  // Content
  #maincontent {
    clear: both;
  }

  .page-main,
  .column.main {
    min-height: initial !important;
  }

  .page-main {
    clear: both;
  }

  .fotorama,
  .fotorama__stage,
  .fotorama__stage__shaft,
  .fotorama__stage {
    width: 300px !important;
    height: 300px !important;
  }

  .product-info-main .page-title-wrapper .page-title {
    margin-top: 0px;
  }

  .fotorama__nav.fotorama__nav--thumbs {
    display: block;
  }

  .product.data.items .item.content {
    border: none;
    padding: 0px;
  }

  .product.data.items > .item.title {
    float: none !important;
    margin: 0px !important;
    padding: 0px !important;
    border: none !important;
  }

  .product.data.items .item.title > .switch,
  .product.data.items .item.title.active > .switch {
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 2px;
    border-bottom-color: #707172;
    margin: 0px !important;
    padding: 0px !important;
    color: #707172 !important;
    font-weight: normal;
    font-size: 1.6em !important;
  }

  .product.data.items > .item.content {
    float: none;
    margin-left: 0px !important;
    margin: 0px;
    padding: 0px;
    background: none;
    display: block !important;
  }

  .ps-container {
    overflow: initial !important;
  }

  .additional-attributes-wrapper {
    max-height: none;
  }

  .product.info.detailed .additional-attributes {
    width: 100%;
    max-width: 100%;
  }

  .modals-wrapper {
    display: block !important;
  }

  .modal-popup {
    display: block !important;
    position: static !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    bottom: auto !important;

    .modal-inner-wrap {
      margin: 0px !important;
      width: auto !important;
      position: static !important;
      left: auto !important;
      right: auto !important;
      top: auto !important;
      bottom: auto !important;
      display: block !important;
      transform: none !important;
    }

    .modal-content {
      background: none;
      padding: 0px;
    }
  }

  .contact-wrapper .formbuilder-form {
    display: none;
  }

  .contact-wrapper .contact-information {
    float: none;
    width: 100%;
  }
}